import React from 'react';
import styled, {keyframes} from 'styled-components';
import {useI18next, Trans, Helmet} from 'gatsby-plugin-react-i18next';
import {styles, media, colors, links} from 'global.config';
import Stars from 'components/Stars';
import Button from 'components/Button';
import Container from 'components/Container';
import CurvedBorder from 'components/CurvedBorder';
import TrustedBy from 'components/TrustedBy';
import PriceCalculator from 'components/PriceCalculator';

import TopSectionImage from 'images/email-marketing-software.svg';
import Pipe from 'images/pipe.svg';
import {ReactComponent as Coins} from 'images/coins.svg';
import AstronautHalf from 'images/astronaut-half.svg';
import WeLoveCrypto from 'images/we-love-crypto.svg';
import Preview1 from 'images/crypto-preview1.jpg';
import Preview2 from 'images/crypto-preview2.jpg';
import CryptoAws from 'images/crypto-aws.svg';
import CryptoRocket from 'images/crypto-rocket.svg';
import CryptoCoins from 'images/crypto-coins.svg';

const IndexImageAnimation = keyframes`
    0% {
      transform: rotate(0deg) translateY(0);
    }
    50% {
      transform: rotate(2deg) translateY(-10px);
    }
    0% {
      transform: rotate(0deg) translateY(0px);
    }
  `;

const IndexPipeAnimation = keyframes`
    0% {
      transform: rotate(-4deg) scale(1, 1);
    }
    50% {
      transform: rotate(-4.2deg) scale(1, 1.05);
    }
    0% {
      transform: rotate(-4deg) scale(1, 1);
    }
  `;

const IndexCoinsAnimation = keyframes`
    0% {
      transform: scale(1, 0.95);
    }
    50% {
      transform: scale(1, 1.05);
    }
    100% {
      transform: scale(1, 0.95);
    }
  `;
const IndexActionAnimation = keyframes`
0% {
  transform: rotate(-5deg);
}
50% {
  transform: rotate(1deg);
}
0% {
  transform: rotate(-5deg);
}
`;

const IndexSection = styled.section`
  text-align: center;
  color: #fff;
  position: relative;
`;

const IndexSectionContainer = styled(Container)`
  padding: 135px ${styles.containerPadding} 80px;
  position: relative;
  min-height: calc(100vh - 170px);
  margin: auto;
  max-width: ${styles.containerWidth};

  @media ${media.mobile} {
    padding-top: 150px;
  }
`;

const IndexSectionImage = styled.img`
  position: relative;
  z-index: 1;
  animation-name: ${IndexImageAnimation};
  animation-duration: 6s;
  animation-iteration-count: infinite;
  @media ${media.mobile} {
    width: 235px;
  }
`;

const IndexSectionSummary = styled.p`
  font-size: 16px;
  line-height: 20px;
  max-width: 400px;
  margin: 30px auto;
  font-weight: 300;
`;

const IndexSectionAction = styled.div`
  transform: rotate(-5deg);
  position: relative;
  display: inline-block;
  margin-top: 25px;
  right: 15px;
  animation-name: ${IndexActionAnimation};
  animation-duration: 6s;
  animation-iteration-count: infinite;
  z-index: 1;
  ${Button} {
    padding-left: 50px;
    padding-right: 50px;
  }
  @media ${media.mobile} {
    right: 0;
  }
`;

const IndexSectionActionImage = styled.img`
  position: absolute;
  top: 35px;
  right: 10px;
  z-index: 1;
`;

const IndexSectionPipe = styled.img`
  position: absolute;
  top: 213px;
  right: calc(50% - 354px);
  z-index: 0;
  width: 317px;
  transform: rotate(-4deg) scale(1, 1);
  animation-name: ${IndexPipeAnimation};
  animation-duration: 6s;
  animation-iteration-count: infinite;
  @media ${media.mobile} {
    display: none;
  }
  @media ${media.tablet} {
    height: 400px;
    right: calc(50% - 330px);
  }
`;
const IndexSectionCoins = styled.div`
  position: absolute;
  top: 225px;
  right: calc(50% - 118px);
  animation-name: ${IndexCoinsAnimation};
  animation-duration: 6s;
  animation-iteration-count: infinite;
`;

const SectionContainer = styled(Container)`
  padding: 30px ${styles.containerPadding};
  position: relative;
`;

const SectionContainerSeperated = styled(SectionContainer)`
  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 10%;
    right: 10%;
    border-bottom: 1px solid #e5e5e5;
  }
`;

const SectionContent = styled.div`
  margin: 0 auto;
  padding: 50px 0;
  max-width: 750px;
  text-align: center;
`;

const SectionSub = styled.div`
  font-size: 19px;
  line-height: 31px;
`;

const SectionText = styled.div`
  color: ${colors.textLight};
  line-height: 20px;
  margin: 25px 0;
`;

const SectionImage = styled.img`
  margin-bottom: 25px;
`;

const PreviewSectionContainer = styled.div`
  padding: 75px ${styles.containerPadding};
  position: relative;
`;

const PreviewSectionRow = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 30px 0;
  &:last-child {
    padding-bottom: 0;
  }
  @media ${media.tabletMobile} {
    flex-direction: column;
    text-align: center;
    padding: 0 0 60px 0;
  }
`;

const PreviewSectionRowRight = styled(PreviewSectionRow)`
  display: flex;
  text-align: right;
  @media ${media.tabletMobile} {
    text-align: center;
    flex-direction: column-reverse;
  }
`;

const PreviewSectionContent = styled.div`
  width: 47%;
  @media ${media.tabletMobile} {
    width: 100%;
  }
`;

const PreviewSectionText = styled.p`
  color: ${colors.textLight};
  line-height: 20px;
  margin: 25px 0;
`;

const PreviewSectionImage = styled.div`
  width: 47%;
  img {
    width: 100%;
    box-shadow: rgba(0, 0, 0, 0.09) 0 12px 30px;
  }
  @media ${media.tabletMobile} {
    padding-bottom: 30px;
    width: 100%;
  }
`;

const FeaturesSection = styled.section`
  color: #fff;
  ${Container} {
    margin-bottom: 125px;
  }
`;

const LastCurvedBorder = styled(CurvedBorder)`
  transform: rotate(180deg);
  top: 3px;
  position: relative;
`;

const Page: React.FC = () => {
  const {t} = useI18next();
  return (
    <>
      <Helmet title={t('Email Marketing for Cryptocurrency and ICO')}>
        <meta
          name="description"
          content={t(
            'Need the most powerful email marketing platform for your ICO or Cryptocurrency? MoonMail is your Choice!'
          )}
        />
        <link itemProp="applicationCategory" href="http://schema.org/OtherApplication" />
        <meta
          itemProp="applicationCategory"
          content={t('Cryptocurrecy and Blockchain Newsletters')}
        />
        <meta itemProp="url" content="https://moonmail.io/cryptocurrency-ico-email-marketing/" />
        <meta itemProp="name" content="MoonMail" />
        <meta itemProp="fileformat" content="application/zip" />
      </Helmet>
      <div className="index-page">
        <Stars>
          <IndexSection>
            <IndexSectionContainer>
              <IndexSectionImage
                src={TopSectionImage}
                alt={t('Cryptocurrecy and Blockchain Email marketing software')}
                title={t('Cryptocurrecy and Blockchain Email marketing software')}
                width={296}
                height={201}
              />
              <h1>
                <Trans>
                  Want to Send <br />
                  Cryptocurrecy and Blockchain <br />
                  Newsletters?
                </Trans>
              </h1>
              <IndexSectionSummary>
                {t('Why don’t you start by sending up to 300 emails / month for free?')}
              </IndexSectionSummary>
              <IndexSectionAction>
                <Button
                  href={links.signUp}
                  title={t('Email marketing software for Cryptocurrecy and Blockchain')}>
                  {t('Get started for Free!')}
                </Button>
                <IndexSectionActionImage src={AstronautHalf} width="48" height="44" />
              </IndexSectionAction>
              <IndexSectionPipe src={Pipe} />
              <IndexSectionCoins>
                <Coins />
              </IndexSectionCoins>
            </IndexSectionContainer>
          </IndexSection>
          <TrustedBy />
        </Stars>
        <section className="section section_divided">
          <SectionContainerSeperated>
            <SectionContent>
              <img
                src={WeLoveCrypto}
                alt={t('Email marketing software for Cryptocurrecy and Blockchain')}
                width="84"
                height="88"
              />
              <SectionSub>
                <Trans>We love</Trans>
              </SectionSub>
              <h2>
                <Trans>Cryptocurrency and Blockchain</Trans>
              </h2>
              <SectionText>
                <Trans>
                  At MoonMail, we recognise the value of blockchain and cryptocurrency and
                  understand the importance of sharing the latest groundbreaking developments and
                  ICOs.
                </Trans>
              </SectionText>
              <Button href={links.signUp}>
                <Trans>Try it for Free</Trans>
              </Button>
            </SectionContent>
          </SectionContainerSeperated>
        </section>
        <section className="preview-section">
          <PreviewSectionContainer>
            <PreviewSectionRow>
              <PreviewSectionImage>
                <img src={Preview1} />
              </PreviewSectionImage>
              <PreviewSectionContent>
                <h2>
                  <Trans>Unlimited Lists and Campaigns</Trans>
                </h2>
                <PreviewSectionText>
                  <Trans>
                    Want to send a different newsletter to your subscribers, depending on their
                    altcoin of choice? With MoonMail you can have unlimited lists within your
                    account, splitting them by interest or any other criteria.
                  </Trans>
                </PreviewSectionText>
                <Button href={links.signUp}>
                  <Trans>Get started for free</Trans>
                </Button>
              </PreviewSectionContent>
            </PreviewSectionRow>
            <PreviewSectionRowRight>
              <PreviewSectionContent>
                <h2>
                  <Trans>Fully Customisable Campaigns</Trans>
                </h2>
                <PreviewSectionText>
                  <Trans>
                    You can design your campaigns exactly how you want them. Upload your own HTML,
                    send a plain text email or use one of our stylish templates - it’s up to you.
                    Edit your email layout, sender address and send time to create the perfect
                    campaign.
                  </Trans>
                </PreviewSectionText>
                <Button href={links.signUp}>
                  <Trans>Get started for free</Trans>
                </Button>
              </PreviewSectionContent>
              <PreviewSectionImage>
                <img src={Preview2} />
              </PreviewSectionImage>
            </PreviewSectionRowRight>
          </PreviewSectionContainer>
        </section>
        <Stars>
          <FeaturesSection>
            <CurvedBorder reversed />
            <SectionContainer>
              <SectionContent>
                <SectionImage
                  src={CryptoAws}
                  alt={t('Cryptocurrecy and Blockchain Email marketing software')}
                  width="71"
                  height="88"
                />
                <h2>
                  <Trans>Great Deliverability via Amazon SES</Trans>
                </h2>
                <SectionText>
                  <Trans>
                    Moonmail uses Amazon Web Services (AWS) and their Simple Email Service (SES) to
                    ensure excellent deliverability at an incredibly low cost.
                  </Trans>
                </SectionText>
                <Button href={links.signUp}>
                  <Trans>Get started for Free!</Trans>
                </Button>
              </SectionContent>
              <SectionContent>
                <SectionImage
                  src={CryptoCoins}
                  alt={t('Email marketing software for Cryptocurrecy and Blockchain')}
                  width="69"
                  height="50"
                />
                <h2>
                  <Trans>We Get Cryptocurrency</Trans>
                </h2>
                <SectionText>
                  <Trans>
                    We know there's a lot more to blockchain than fluctuating values of Bitcoin and
                    questionable ICOs. Our team has experience working in the cryptocurrency
                    industry and are long-term backers of Bitcoin and Ethereum. While many email
                    providers, including MailChimp, have a blanket ban on cryptocurrency
                    newsletters, we promise to support genuine crypto enthusiasts and disruptive
                    companies working with blockchain.
                  </Trans>
                </SectionText>
                <Button href={links.signUp}>
                  <Trans>Get started for Free!</Trans>
                </Button>
              </SectionContent>
              <SectionContent>
                <SectionImage
                  src={CryptoRocket}
                  alt={t('Email marketing software for Cryptocurrecy and Blockchain')}
                  width="189"
                  height="150"
                />
                <h2>
                  <Trans>Email Marketing at a Low Cost</Trans>
                </h2>
                <SectionText>
                  <Trans>
                    MoonMail offers all the core features of an email marketing provider but at a
                    significantly lower cost. Why not compare our prices to some of the competition
                    below?
                  </Trans>
                </SectionText>
                <Button href={links.signUp}>
                  <Trans>Get started for Free!</Trans>
                </Button>
              </SectionContent>
            </SectionContainer>
            <LastCurvedBorder reversed />
          </FeaturesSection>
        </Stars>
        <PriceCalculator
          css={`
            margin: 0;
            top: -100px;
            position: relative;
          `}
        />
      </div>
    </>
  );
};

export default Page;
